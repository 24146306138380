import { AxiosResponse } from "axios";
import http from "@/common/utils/http-common-utils";
import ApiRouterConstants from "../../constants/api.router.constants";
import {
  ApiClinicSettingStaffShiftReq,
  ApiClinicSettingStaffShiftParams,
  IClinicSettingStaffShift,
  ApiClinicSettingStaffShiftIndexParams,
  IStaffShiftApplyPrevMonth,
} from "../../typings";
import { useAxios, EasyUseAxiosReturn } from "@vueuse/integrations/useAxios";
import { AnyFn, Fn } from "@vueuse/shared";

const ClinicStaffShiftsService = {
  getShifts: async (
    params: ApiClinicSettingStaffShiftIndexParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IClinicSettingStaffShift>, AxiosResponse, IClinicSettingStaffShift>> => {
    const { execute } = useAxios<AxiosResponse<IClinicSettingStaffShift>, AxiosResponse, IClinicSettingStaffShift>(
      ApiRouterConstants.API_STAFF_SHIFTS,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  getShift: async (
    params: ApiClinicSettingStaffShiftParams,
    onSuccess: AnyFn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IClinicSettingStaffShift>, AxiosResponse, IClinicSettingStaffShift>> => {
    const { execute } = useAxios<AxiosResponse<IClinicSettingStaffShift>, AxiosResponse, IClinicSettingStaffShift>(
      ApiRouterConstants.API_STAFF_SHIFT,
      {
        method: "GET",
        params,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  updateShift: async (
    params: ApiClinicSettingStaffShiftParams,
    payload: ApiClinicSettingStaffShiftReq,
    onSuccess: Fn,
    onError: AnyFn
  ): Promise<EasyUseAxiosReturn<AxiosResponse<IClinicSettingStaffShift>, AxiosResponse, IClinicSettingStaffShift>> => {
    const { execute } = useAxios<AxiosResponse<IClinicSettingStaffShift>>(
      ApiRouterConstants.API_STAFF_SHIFT,
      {
        method: "PATCH",
        params,
        data: payload as ApiClinicSettingStaffShiftReq,
      },
      http,
      {
        immediate: false,
        onSuccess,
        onError,
      }
    );
    return await execute();
  },
  applyPrevMonthShift: async (
    payload: IStaffShiftApplyPrevMonth,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<
    EasyUseAxiosReturn<AxiosResponse<IStaffShiftApplyPrevMonth>, AxiosResponse, IStaffShiftApplyPrevMonth>
  > => {
    const { execute } = useAxios<AxiosResponse<IStaffShiftApplyPrevMonth>, AxiosResponse, IStaffShiftApplyPrevMonth>(
      ApiRouterConstants.API_STAFF_SHIFT_APPLY_PREV_MONTH,
      {
        method: "PATCH",
      },
      http,
      {
        immediate: false,
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.response?.data?.errors);
        },
      }
    );
    return await execute({ data: payload });
  },
};

export default ClinicStaffShiftsService;
