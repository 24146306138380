import { LineEmojiServices } from "@/clinic/services";

export default class MessageUtils {
  public static parseContentToShortMessage(content: any, type: string): any {
    const maxLength = 45;
    const maxSentenceBreaks = 3;
    if (type === "text") {
      if (content.length > maxLength) {
        content = content.substring(0, maxLength) + " ...";
      } else {
        const sentences = content.split("\n");
        if (sentences.length > maxSentenceBreaks) {
          content = sentences.slice(0, maxSentenceBreaks).join("\n") + " ...";
        }
      }
      return content.replace(/\n/g, "<br>");
    } else {
      switch (type) {
        case "image":
          return "画像を送信しました";
        case "sticker":
          return "スタンプを送信しました";
        case "audio":
          return "記録を送信しました";
        case "video":
          return "動画を送信しました";
        default:
          return "不明なメッセージタイプ";
      }
    }
  }

  public static convertEmojisToImage = (message: any): string => {
    if (message.emojis == null) {
      return message.text_content;
    } else {
      const startText = message.text_content.slice(0, message.emojis[0]?.index);
      const result = message.emojis.map((emoji: any, i: number) => {
        const startIndex = emoji.index;
        const currentText = `<img src="${LineEmojiServices.getEmojiUrl({
          emoji_id: emoji.emojiId,
          product_id: emoji.productId,
        })}" class="emoji" />`;
        const nextIndex = message.emojis[i + 1]?.index;
        const untilNextText = message.text_content.slice(startIndex + emoji.length, nextIndex);

        return currentText + untilNextText;
      });
      return startText + result.join("");
    }
  };

  public static highlightSearchResult = (text: string, term: string): string => {
    const queries = term.split(" ");
    const matches = [...text.matchAll(new RegExp(`<img[^>]*.>|(${queries.join("|")})`, "g"))];
    const startText = text.slice(0, matches[0]?.index);

    const result = matches.map((match, i) => {
      const startIndex = match.index;
      const currentText = match[0];
      const endIndex = (startIndex || 0) + currentText.length;
      const nextIndex = matches[i + 1]?.index;
      const untilNextText = text.slice(endIndex, nextIndex);

      if (currentText.includes("<img")) {
        return currentText + untilNextText;
      } else {
        return '<span class="highlight">' + currentText + "</span>" + untilNextText;
      }
    });

    return startText + result.join("");
  };
}
